import { updateCustomAnalyticsExtraDataForCompaniesInListAction } from 'actions/company_extra_data/update-extra-data-for-companies-in-list-action';
import { highlightColumnAction } from 'actions/explore-table-ui/highlight-column-action';
import { addExploreCustomColumnAction } from 'actions/explore/add-discovery-custom-column-action';
import { CHAT_GPT_MODEL_VERSION } from 'constants/custom-analytics';
import {
  addPostQueryToThread,
  getThreadKey,
  updatePostIdOfDataType
} from 'reducer/custom-analytics';
import { getCurrentFolderId } from 'selectors/folders';

export function addEmptyCustomAnalyticsColumnAction({
  listId,
  columnName,
  question,
  companyMetaIds,
  onCreate,
  answerType
}) {
  return (dispatch, getState) => {
    const state = getState();
    const threadKey = getThreadKey(listId);
    const folderId = getCurrentFolderId(state);

    const info = {
      question,
      company_list_id: listId,
      folder_id: folderId,
      model: CHAT_GPT_MODEL_VERSION,
      answer_type: answerType || 'text'
    };
    const postId = new Date().getTime();

    dispatch(addPostQueryToThread({ threadKey, postId, query: question, body: info }));
    dispatch(
      addExploreCustomColumnAction(
        listId,
        columnName,
        info,
        'custom_analytics',
        answerType,
        ({ id: newColumnId }) => {
          dispatch(updatePostIdOfDataType({ postId, dataType: newColumnId }));
          dispatch(
            updateCustomAnalyticsExtraDataForCompaniesInListAction(
              listId,
              newColumnId,
              false,
              companyMetaIds,
              threadKey,
              postId
            )
          );
          dispatch(highlightColumnAction(listId, newColumnId, true));
          onCreate?.(newColumnId);
        }
      )
    );
  };
}
