import { Typography, alpha } from '@mui/material';
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro';
import { highlightColumnAction } from 'actions/explore-table-ui/highlight-column-action';
import { handleErrorAction } from 'actions/users/handle-error-action';
import { sendExploreEvent } from 'actions/users/send-user-event-action';
import { USER_EVENTS } from 'constants/userEvents';
import { dispatch as oldDispatch } from 'hooks/AppStateProvider';
import { useScrollPosition } from 'hooks/useScrollDetector';
import { useWindowSize } from 'hooks/useWindowSize';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateExploreFilteredCompanies } from 'reducer/explore-table-ui';
import { getExploreFilteredCompanies } from 'selectors/explore-table-ui';
import { appTheme } from 'theme';
import { getCustomAnalyticsMetaColumnId } from 'utils/custom-analytics-utils';
import { isEmpty } from 'utils/objects-utils';
import {
  determinAlign,
  determineCellBorder,
  determineRenderCell,
  determineRenderHeader,
  getColumnFlex,
  getColumnMinWidth,
  getColumnSort,
  getColumnWidth
} from 'utils/table-utils';

import BenchmarkHeader from './BenchmarkHeader';
import CustomColumnMenu from './CustomColumnMenu';
import CustomPagination from './CustomPagination';
import DiscoveryTableLoader from './DiscoveryTableLoader';
import { tableIcons } from './RenderUrlIcon';
import './TableComponent.scss';
import TableComponentToolbar from './TableComponentToolbar';

/* eslint-disable max-lines-per-function */
/* eslint-disable max-lines */
export default function DiscoveryTable({
  listId,
  viewId,
  title,
  columns,
  columnGrouping,
  showTitle,
  elementId,
  onCellClick,
  onColumnResize,
  createEmptyRow,
  isLoadingRows,
  tableHeaderHeight,
  setIsFullScreen,
  filterRow,
  hiddenColumns,
  disableToolBar,
  toolbarArgs,
  scrollElement,
  runningRows,
  highlightedColumns
}) {
  const dispatch = useDispatch();
  const parentRef = useRef();
  const scrollPosition = useScrollPosition(scrollElement);
  const windowSize = useWindowSize();
  const [width, setWidth] = useState(100);
  const [height, setHeight] = useState(100);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsCount, setRowsCount] = useState(0);
  const [filteredCompanyMetaIds, setFilteredCompanyMetaIds] = useState(null);
  const apiRef = useGridApiRef();
  const stateFilteredCompanyMetaIds = useSelector((state) =>
    getExploreFilteredCompanies(state, listId)
  );

  const handleHightlightedColumn = useCallback(
    (columnHeaderElement, columnId) => {
      const highlightBorderStyle = `2px dashed ${appTheme.palette.primary.primary3_50}`;
      const originalBorderStyle = columnHeaderElement.style.border;
      if (!originalBorderStyle?.includes('2px dashed')) {
        let useNewStyle = false;
        columnHeaderElement.style.border = highlightBorderStyle;
        const intervalId = setInterval(() => {
          columnHeaderElement.style.border = useNewStyle
            ? highlightBorderStyle
            : originalBorderStyle;
          useNewStyle = !useNewStyle;
        }, 500);
        setTimeout(() => {
          clearInterval(intervalId);
          columnHeaderElement.style.border = originalBorderStyle;
          dispatch(highlightColumnAction(listId, columnId, false));
        }, 5000);
      }
    },
    [listId, dispatch]
  );

  useEffect(() => {
    if (!apiRef.current || isEmpty(highlightedColumns)) return;
    highlightedColumns.forEach((columnId) => {
      const colIndex = apiRef.current.getColumnIndex(columnId);
      apiRef.current.scrollToIndexes({ colIndex });
      requestAnimationFrame(() => {
        const columnHeader = apiRef.current.getColumnHeaderElement(columnId);
        if (columnHeader) {
          handleHightlightedColumn(columnHeader, columnId);
        }
      });
    });
  }, [highlightedColumns, apiRef, handleHightlightedColumn]);

  useEffect(() => {
    if (!parentRef.current) return;

    if (width !== parentRef.current.offsetWidth) {
      setWidth(parentRef.current.offsetWidth);
    }

    if (height !== parentRef.current.offsetHeight) {
      setHeight(parentRef.current.offsetHeight);
    }
  }, [parentRef.current?.offsetWidth, parentRef.current?.offsetHeight, width, height]);

  const sxExtra = determineCellBorder(elementId)
    ? {
        '.MuiDataGrid-cell': {
          border: determineCellBorder(elementId) ? '0.5px solid' : 'none',
          borderColor: 'greyColors.grey100'
        },
        '.MuiDataGrid-columnsPanelRow': {
          border: determineCellBorder(elementId) ? '0.5px solid' : 'none',
          borderColor: 'greyColors.grey100'
        },
        '.MuiDataGrid-columnHeaders': {
          border: determineCellBorder(elementId) ? '0.5px solid' : 'none',
          borderColor: 'greyColors.grey100',
          backgroundColor: 'background.bg100'
        },
        '.MuiDataGrid-pinnedColumnHeaders': {
          border: 'none',
          backgroundColor: 'background.bg100',
          boxShadow: 'none'
        },
        '.MuiDataGrid-columnHeader': {
          border: '1px solid white'
        },
        '.table-section': {
          border: '1px solid white',
          backgroundColor: 'background.bg50'
        },
        '.MuiDataGrid-columnHeader--filledGroup': {
          '.MuiDataGrid-columnHeaderTitleContainer': {
            borderBottom: 'none'
          }
        },
        '.running': { padding: 0, borderColor: 'white', borderLeft: 'none', borderRight: 'none' },
        '.highlighted': {
          backgroundColor: alpha(appTheme.palette.primary.primary0, 0.15),
          borderColor: 'greyColors.grey150'
        }
      }
    : {};

  const dataGridColumns = useMemo(() => {
    const companyColumnName = `Company ${rowsCount ? `(${rowsCount})` : ''}`;
    const colIdToGroupIndex = columnGrouping?.reduce((prev, group, index) => {
      group.children.forEach((col) => {
        prev[col.field] = index;
      });
      return prev;
    }, {});
    return columns
      .sort((col1, col2) => colIdToGroupIndex?.[col1.id] - colIdToGroupIndex?.[col2.id])
      .map((col) => ({
        field: col.id,
        headerName: col.id === 'company_name' ? companyColumnName : col.name,
        flex:
          col.data_type === 'url_icon' || col.data_type === 'add_column'
            ? null
            : getColumnFlex(elementId, col.id, col.width),
        width:
          col.data_type === 'url_icon' || col.data_type === 'add_column'
            ? 60
            : getColumnWidth(elementId, col.id, col.width),
        minWidth:
          col.data_type === 'url_icon' || col.data_type === 'add_column'
            ? 60
            : getColumnMinWidth(elementId, col.id, col.data_type),
        url: col?.url,
        icon: col?.icon,
        type: col?.data_type,
        description: col?.description,
        disableColumnMenu: !col.extraParams,
        disableReorder: true,
        hideSortIcons:
          col.data_type === 'url_icon' ||
          col.data_type === 'url' ||
          col.data_type === 'multiple_url_icons' ||
          col.disableSort,
        sortable:
          col.data_type !== 'url_icon' ||
          col.data_type === 'url' ||
          col.data_type === 'multiple_url_icons',
        align: determinAlign(col, tableIcons),
        headerAlign: 'left',
        renderHeader: col?.benchmarkLabel
          ? (props) => <BenchmarkHeader {...props} benchmarkLabel={col.benchmarkLabel} />
          : determineRenderHeader(col?.description ? 'variable' : col.data_type),
        renderCell: determineRenderCell(col.data_type) || determineRenderCell(col.id),
        hide:
          col.data_type === 'meta' ||
          col.data_type === 'custom_analytics_meta' ||
          hiddenColumns[col.id],
        valueFormatter: col.valueFormatter,
        ...(getColumnSort(elementId, col.id) && {
          sortComparator: getColumnSort(
            elementId,
            col.data_type?.includes('custom') ? col.data_type : col.id
          )
        }),
        valueOptions: col.valueOptions,
        ...(col.filterOperators && {
          filterOperators: col.filterOperators
        }),
        filterable: !col.disableFilter,
        disableExport: col?.disableExport,
        colSpan: ({ row }) => {
          if (row.fullRow) return 50;
          return null;
        },
        extraParams: col.extraParams
      }));
  }, [columns, elementId, hiddenColumns, rowsCount, columnGrouping]);

  const csvFields = useMemo(() => {
    const fields = ['company_name'];
    dataGridColumns
      .filter((column) => column.field !== 'company_name' && !column.hide && !column.disableExport)
      .forEach((column) => {
        fields.push(column.field);
        if (column.type === 'custom_analytics') {
          fields.push(getCustomAnalyticsMetaColumnId(column.field));
        }
      });
    return fields;
  }, [dataGridColumns]);

  const fisrtNoneHiddenColumnId = useMemo(
    () => dataGridColumns?.find((col) => col.hide !== true)?.field,
    [dataGridColumns]
  );

  const [dataGridRows, allRows] = useMemo(() => {
    const localDataGridRows = [];
    const localAllRows = [];
    const companyListMetaIds = [];
    const numRows = columns[0].values.length;
    const startIndex = runningRows?.length || 0;
    for (let index = 0; index < numRows; index++) {
      const row = columns.reduce((obj, col) => ({ ...obj, [col.id]: col.values[index] }), {
        id: index + startIndex
      });
      localAllRows.push(row);
      if (filterRow) {
        if (filterRow(row)) {
          localDataGridRows.push(row);
          companyListMetaIds.push(row.company_name.companyListMetaId);
        }
      } else {
        localDataGridRows.push(row);
      }
    }
    setRowsCount(localDataGridRows.length);
    setFilteredCompanyMetaIds(companyListMetaIds);
    if (createEmptyRow) {
      localDataGridRows.push(createEmptyRow(numRows + startIndex, fisrtNoneHiddenColumnId));
    }
    if (runningRows) {
      localDataGridRows.unshift(
        ...runningRows.map((row) => ({ ...row, [fisrtNoneHiddenColumnId]: row.runningData }))
      );
    }

    return [localDataGridRows, localAllRows];
  }, [columns, fisrtNoneHiddenColumnId, createEmptyRow, filterRow, runningRows]);

  // Stops constant dispatch of the same event
  useEffect(() => {
    if (
      [...(filteredCompanyMetaIds || [])].sort().join(',') !==
      [...(stateFilteredCompanyMetaIds || [])].sort().join(',')
    ) {
      dispatch(
        updateExploreFilteredCompanies({ listId, filteredCompanies: [...filteredCompanyMetaIds] })
      );
    }
  }, [filteredCompanyMetaIds, stateFilteredCompanyMetaIds, dispatch, listId]);

  const numCompanies =
    dataGridRows.length - (createEmptyRow ? 1 : 0) - (runningRows ? runningRows.length : 0);

  const heights = {
    rowHeight: 42,
    headerHeight: 42,
    toolbarHeight: disableToolBar ? 0 : 60,
    marginTop: 6
  };

  const allRowsSize = dataGridRows.length * heights.rowHeight;
  const headerAndHeaderGroupSize = 2 * heights.headerHeight;
  const tableMaxHeight = allRowsSize + headerAndHeaderGroupSize + heights.toolbarHeight;
  const tableScrollbarSpace = 32;
  const aboveTableContentHeight = tableHeaderHeight + heights.marginTop + tableScrollbarSpace;
  const isTableScrollable =
    windowSize[1] - aboveTableContentHeight - tableScrollbarSpace <= tableMaxHeight;
  const tableHeight = windowSize[1] - Math.max(0, aboveTableContentHeight - scrollPosition);

  useEffect(() => {
    setIsFullScreen(isTableScrollable);
  }, [isTableScrollable, setIsFullScreen]);

  if (columns.length < 1) {
    return <Typography variant="h3">No table data found.</Typography>;
  }

  return (
    <div style={{ width: '100%' }}>
      {showTitle && (
        <React.Fragment>
          <Typography variant="paragraphBold" color="secondary.secondary6">
            {title}
          </Typography>
          <br />
        </React.Fragment>
      )}
      <DataGridPro
        onError={(errorData) =>
          oldDispatch(handleErrorAction(errorData.error, errorData.errorInfo))
        }
        initialState={{ pinnedColumns: { left: ['company_name'] } }}
        apiRef={apiRef}
        rows={dataGridRows}
        columns={dataGridColumns}
        headerHeight={heights.headerHeight}
        rowHeight={heights.rowHeight}
        autoHeight={!isTableScrollable}
        experimentalFeatures={{ columnGrouping: Boolean(columnGrouping) }}
        columnGroupingModel={columnGrouping}
        disableSelectionOnClick={true}
        rowsPerPageOptions={[Math.min(4, dataGridRows.length)]}
        hideFooter={true}
        pagination={false}
        page={currentPage}
        onPageChange={(newPage) => setCurrentPage(newPage)}
        onCellClick={onCellClick}
        onColumnResize={onColumnResize}
        components={{
          Pagination: CustomPagination,
          Toolbar: disableToolBar ? null : TableComponentToolbar,
          LoadingOverlay: DiscoveryTableLoader,
          ColumnMenu: CustomColumnMenu
        }}
        onSortModelChange={(data) => {
          if (data?.[0]?.field) {
            dispatch(
              sendExploreEvent(
                USER_EVENTS.COLUMN_SORTING,
                listId,
                `Sorted list by ${data[0].field}`,
                {
                  column: data[0].field,
                  listId,
                  sort: data[0].sort
                }
              )
            );
          }
        }}
        getCellClassName={(params) => {
          if (params.value?.running) {
            return 'running';
          }
          if (params.row.company_name?.highlighted) {
            return 'highlighted';
          }
          return '';
        }}
        loading={isLoadingRows}
        componentsProps={{
          toolbar: {
            csvOptions: {
              fields: csvFields,
              fileName: title,
              getRowsToExport: () =>
                dataGridRows.filter((row) => row.company_name?.name).map((row) => row.id)
            },

            isEmpty: numCompanies <= 0,
            elementId,
            viewId,
            allRows,
            filteredRowsNum: numCompanies,
            listId,
            ...toolbarArgs
          },
          loadingOverlay: {
            listId
          }
        }}
        sx={{
          marginTop: `${heights.marginTop}px`,
          height: tableHeight,
          ...sxExtra,
          zIndex: 0,
          '.MuiDataGrid-columnSeparator': {
            display: 'block',
            color: 'transparent'
          },
          '.MuiDataGrid-columnSeparator--resizable': {
            '&:hover': {
              color: 'transparent'
            },
            '&.MuiDataGrid-columnSeparator--resizing': {
              color: 'transparent'
            }
          },
          '.MuiDataGrid-columnHeaderTitleContainerContent': {
            width: '100%'
          }
        }}
      />
    </div>
  );
}

DiscoveryTable.propTypes = {
  listId: PropTypes.number,
  viewId: PropTypes.string,
  title: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.object),
  columnGrouping: PropTypes.arrayOf(PropTypes.object),
  showTitle: PropTypes.bool,
  elementId: PropTypes.string,
  pageSize: PropTypes.number,
  usePagination: PropTypes.bool,
  onCellClick: PropTypes.func,
  onColumnResize: PropTypes.func,
  createEmptyRow: PropTypes.func,
  isLoadingRows: PropTypes.bool,
  tableHeaderHeight: PropTypes.number,
  setIsFullScreen: PropTypes.func,
  filterRow: PropTypes.func,
  hiddenColumns: PropTypes.object,
  disableToolBar: PropTypes.bool,
  toolbarArgs: PropTypes.object,
  scrollElement: PropTypes.object,
  runningRows: PropTypes.array,
  highlightedColumns: PropTypes.array
};

DiscoveryTable.defaultProps = {
  disableToolBar: false,
  hiddenColumns: {}
};
