import { ThemeProvider } from '@mui/material';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import { wrapWithError } from 'components/ErrorBoundaryComponent';
import AppRouter from 'components/routers/AppRouter';
import { AppStateProvider } from 'hooks/AppStateProvider';
import { AuthProvider } from 'hooks/AuthProvider';
import { ClientIdProvider } from 'hooks/ClientIdProvider';
import { PusherProvider } from 'hooks/PusherProvider';
import React, { useEffect } from 'react';
import { appTheme } from 'theme';

import './App.css';
import { AppUtilsProvider } from './AppUtilsProvider';

// eslint-disable-next-line no-undef
LicenseInfo.setLicenseKey(process.env.REACT_APP_DATA_GRID_PRO);

function App() {
  useEffect(() => {
    const register = async () => {
      await navigator.serviceWorker.register('/sw.js', {
        scope: '.'
      });
    };
    if ('serviceWorker' in navigator) {
      register();
    }
  }, []);
  return (
    <ThemeProvider theme={appTheme}>
      <AppStateProvider>
        <AuthProvider>
          <ClientIdProvider>
            <PusherProvider>
              <AppUtilsProvider>
                <AppRouter />
              </AppUtilsProvider>
            </PusherProvider>
          </ClientIdProvider>
        </AuthProvider>
      </AppStateProvider>
    </ThemeProvider>
  );
}

export default wrapWithError(App);
