import { COMPANY_EXTRA_DATA_TYPES } from 'constants/company-extra-data';
import { COLUMN_TYPES } from 'utils/explore-table-columns';

import { isEmpty } from './objects-utils';
import { formatTimestamp } from './time-utils';

function formatNumber(number) {
  if (!number) return null;
  const zerosPattern = /[.,]00$/u;
  const numInMil = parseFloat((number / 1e6).toFixed(1).replace(zerosPattern, ''));
  if (numInMil < 0.1) {
    return `<$0.1M`;
  }
  return `$${numInMil.toLocaleString()}M`;
}

export const getValueByName = (row, columnName) => row?.[columnName];
export const getValueDefault = (row, column) => getValueByName(row, COLUMN_TYPES[column]);
export const getValueFromExtraData = (row, dataType) => {
  const extraData = row?.company_extra_data?.[dataType];
  if (extraData?.status === 'LOADING') {
    return { loading: true };
  }
  return extraData?.data;
};
const getLeaders = (row) => {
  const value = getValueFromExtraData(row, COMPANY_EXTRA_DATA_TYPES.LEADERS);
  if (value?.loading) return value;
  return value?.leaders;
};
const getFundingData = (row, column) => {
  const showFunding = row?.last_equity_round_type !== 'Public';
  return showFunding ? getValueDefault(row, column) : null;
};
const getFilesValue = (row) => {
  return {
    companyListId: row?.companyListId,
    companyListMetaId: row?.companyListMetaId,
    files: row?.files
  };
};
const getFinanceData = (row) => {
  const extraDataRevenues = getValueFromExtraData(row, COMPANY_EXTRA_DATA_TYPES.REVENUES);
  if (!isEmpty(extraDataRevenues)) {
    return {
      loading: extraDataRevenues?.loading,
      data:
        !extraDataRevenues?.loading &&
        !(extraDataRevenues?.consensus?.is_estimated && row?.last_equity_round_type === 'Public')
          ? extraDataRevenues
          : null
    };
  }
};

const getPeerFinanceData = (row) => {
  if (!row?.financePeerData?.reference_companies) return null;
  return row?.financePeerStatus === 'LOADING'
    ? { loading: true }
    : {
        ...row?.financePeerData,
        reference_companies: row?.financePeerData?.reference_companies?.map(
          (item, refCompIndex) => ({
            ...item,
            id: item?.id ? item?.id : refCompIndex
          })
        ),
        listId: row?.companyListId,
        companyMetaId: row?.companyMetaId,
        company: {
          name: row.cb_name || row.name,
          logoUrl: row.logo_url,
          website: row.company_website,
          description: row.description,
          num_employees: row.num_employees
        }
      };
};
const getCompanyNameValueDefault = (row) => {
  return {
    name: row.cb_name || row.name,
    logoUrl: row.logo_url,
    website: row.company_website,
    linkedinUrl: row.linkedin_url,
    recordId: row.recordId,
    companyMetaId: row.companyMetaId,
    companyListMetaId: row.companyListMetaId,
    recordTimeSubmitted: row.recordTimeSubmitted,
    recordReady: row.recordReady,
    suggestion: row,
    hash: row.id,
    allowCheckbox: true,
    notFound: row.notFound,
    companyListId: row.companyListId,
    showAmbiguousWebsite: row.showAmbiguousWebsite,
    cbPermalink: row.cb_permalink
  };
};
const getCompanyNameValueExplore = (row) => {
  return {
    ...getCompanyNameValueDefault(row),
    allowPeerFinance: true,
    highlighted: row.highlighted
  };
};
export const getCompanyNameValueClusters = (row) => {
  return {
    ...getCompanyNameValueDefault(row),
    hideRecordReady: true
  };
};

export const getCompanyNameValuePeerFinance = (row, _column, isChecked, onCheck) => {
  return {
    ...getCompanyNameValueDefault(row),
    hideMenu: true,
    dimUnchecked: true,
    isChecked,
    onCheck: onCheck
      ? (newChecked) => {
          onCheck(row.id, newChecked);
        }
      : null,
    variant: 'paragraphMedium',
    color: isChecked ? 'primary.primary100' : 'greyColors.grey150'
  };
};
export const getDescriptionPeerFinance = (row, column, isChecked) => {
  return {
    color: isChecked ? 'primary.primary100' : 'greyColors.grey150',
    text: getValueDefault(row, column)
  };
};
const getSummaryValue = (row) => {
  const value = getValueFromExtraData(row, COMPANY_EXTRA_DATA_TYPES.SUMMARY);
  if (value?.loading) return value;
  return value?.summary;
};
const getHiddenGemsValue = (row) => {
  return row?.hiddenGemsData?.leadership_data || row?.hiddenGemsData?.growth_data
    ? [...(row.hiddenGemsData?.leadership_data || []), ...(row.hiddenGemsData?.growth_data || [])]
        .map((item, index2) => `${index2 + 1}) ${item.message}`)
        .join('\n')
    : null;
};

const getLastEquityValue = (row) => {
  const round = getValueByName(row, COLUMN_TYPES.LAST_EQUITY_ROUND_TYPE);
  const hoverText = getValueByName(row, 'last_equity_round_message');
  return { data: round, text: hoverText };
};

const getCreatedAtValue = (row, column) => {
  const value = getValueDefault(row, column);
  return value && formatTimestamp(value, 'dd.MM.yyyy');
};

export const COLUMN_TYPES_TO_EXTRACTORS = Object.freeze({
  COMPANY: getCompanyNameValueExplore,
  INDUSTRIES: (row, column) => getValueDefault(row, column)?.join(', '),
  SUMMARY: getSummaryValue,
  REVENUES: getFinanceData,
  FINANCE_PEER_COLUMN: getPeerFinanceData,
  NUM_EMPLOYEES: (row, column) => getValueDefault(row, column)?.toLocaleString(),
  TOTAL_FUNDING: (row, column) => formatNumber(getValueDefault(row, column)),
  LEAD_INVESTORS: getFundingData,
  FILES: getFilesValue,
  LEADERS: getLeaders,
  HIDDEN_GEMS: getHiddenGemsValue,
  LAST_EQUITY_ROUND_TYPE: getLastEquityValue,
  CREATED_AT: getCreatedAtValue
});

export const CLUSTETRS_COLUMN_TYPES_TO_EXTRACTORS = Object.freeze({
  COMPANY: getCompanyNameValueClusters
});

export const PEER_COLUMN_TYPES_TO_EXTRACTORS = Object.freeze({
  COMPANY: getCompanyNameValuePeerFinance,
  DESCRIPTION: getDescriptionPeerFinance,
  REV_PER_HEAD: (row) => getValueByName(row, 'revenue')
});
