export function getRunProgressChannelName(recordId) {
  // eslint-disable-next-line no-undef
  return `private-${process.env.REACT_APP_PUSHER_PREFIX || 'demo'}-record-${recordId}`;
}

export function getListChannelName(listId) {
  // eslint-disable-next-line no-undef
  return `private-${process.env.REACT_APP_PUSHER_PREFIX || 'demo'}-list-${listId}`;
}

export function getGeneralChannelName() {
  // eslint-disable-next-line no-undef
  return `private-${process.env.REACT_APP_PUSHER_PREFIX || 'demo'}-general`;
}

export function getHomePageChannelName(user_id) {
  // eslint-disable-next-line no-undef
  return `private-${process.env.REACT_APP_PUSHER_PREFIX || 'demo'}-homepage-${user_id}`;
}
