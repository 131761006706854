/* eslint-disable max-lines */
import { createSlice } from '@reduxjs/toolkit';

const initialSliceState = {
  companiesIsChecked: {},
  columns: {},
  exploreCompaniesLoading: {},
  filteredCompanies: {},
  predefinedColumns: null,
  csv: {},
  closedCsvAddColumnsDialog: {},
  highlightedColumns: {},
  csvColumnsToUpload: {}
};

export const exploreTableUISlice = createSlice({
  name: 'explore-table-ui',
  initialState: initialSliceState,
  reducers: {
    updateExploreListCompaniesIsChecked: (sliceState, { payload }) => {
      const { listId, companyListMetaId, checked } = payload;
      const listCompanies = sliceState.companiesIsChecked[listId] || {};
      if (checked) {
        listCompanies[companyListMetaId] = true;
      } else {
        delete listCompanies?.[companyListMetaId];
      }
      sliceState.companiesIsChecked[listId] = listCompanies;
    },
    setExploreListSelectedCompanies: (sliceState, { payload }) => {
      const { listId, selectedComapnies } = payload;
      const newCompaniesIsChecked =
        selectedComapnies?.reduce((result, companyListMetaId) => {
          result[companyListMetaId] = true;
          return result;
        }, {}) || {};
      sliceState.companiesIsChecked[listId] = newCompaniesIsChecked;
    },
    clearExploreTableUIState: (sliceState, { payload }) => {
      const { listId } = payload;
      delete sliceState.companiesIsChecked?.[listId];
    },
    updateExploreTableColumnWidth: (sliceState, { payload }) => {
      const { listId, columnId, width } = payload;
      const listColumns = sliceState.columns[listId] || {};
      listColumns[columnId] = listColumns[columnId] || {};
      listColumns[columnId].width = width;
      sliceState.columns[listId] = listColumns;
    },
    updateExploreCompaniesLoading: (sliceState, { payload }) => {
      const { names, listId } = payload;
      const namesDict = names.reduce((acc, name) => {
        return { ...acc, [name]: 'loading' };
      }, {});
      sliceState.exploreCompaniesLoading[listId] = namesDict;
    },
    removeExploreCompaniesLoading: (sliceState, { payload }) => {
      const { names, listId } = payload;
      names.forEach((name) => delete sliceState.exploreCompaniesLoading[listId]?.[name]);
      if (
        sliceState.exploreCompaniesLoading[listId] &&
        !Object.keys(sliceState.exploreCompaniesLoading[listId]).length
      ) {
        delete sliceState.exploreCompaniesLoading[listId];
      }
    },
    updateExploreFilteredCompanies: (sliceState, { payload }) => {
      const { listId, filteredCompanies } = payload;
      const currentCompanies = sliceState.filteredCompanies[listId] || [];
      if (currentCompanies.sort().join(',') !== filteredCompanies.sort().join(',')) {
        sliceState.filteredCompanies[listId] = filteredCompanies;
      }
    },
    updateExploreCsv: (sliceState, { payload }) => {
      const { listId, csv } = payload;
      sliceState.csv[listId] = csv;
      sliceState.closedCsvAddColumnsDialog[listId] = false;
    },
    addPredefinedColumns: (sliceState, { payload }) => {
      const { predefined_columns } = payload;
      sliceState.predefinedColumns = predefined_columns;
    },
    markAddColumnsCsvDialog: (sliceState, { payload }) => {
      const { listId, isClosed } = payload;
      sliceState.closedCsvAddColumnsDialog[listId] = isClosed;
    },
    highlightColumn: (sliceState, { payload }) => {
      const { listId, columnId, shouldHightlight } = payload;
      if (!sliceState.highlightedColumns[listId]) {
        sliceState.highlightedColumns[listId] = {};
      }
      sliceState.highlightedColumns[listId][columnId] = shouldHightlight;
    },
    updateCsvColumnsToUpload: (sliceState, { payload }) => {
      const { listId, columns } = payload;
      sliceState.csvColumnsToUpload[listId] = columns;
    }
  }
});

export const {
  clearExploreTableUIState,
  updateExploreListCompaniesIsChecked,
  setExploreListSelectedCompanies,
  updateExploreTableColumnWidth,
  updateExploreCompaniesLoading,
  removeExploreCompaniesLoading,
  updateExploreFilteredCompanies,
  updateExploreCsv,
  addPredefinedColumns,
  markAddColumnsCsvDialog,
  highlightColumn,
  updateCsvColumnsToUpload
} = exploreTableUISlice.actions;

export default exploreTableUISlice.reducer;
