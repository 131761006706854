export function toTitleCase(string) {
  const words = string.split(' ');
  return words.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}

export function alphabeticalCaseInsensitive(string1, string2) {
  return string1.toLowerCase().localeCompare(string2.toLowerCase());
}

export function toTypeTitle(artType) {
  return toTitleCase(artType.split('_').join(' '));
}

export function simplifyDomain(string) {
  return new URL(`https://${string}`).hostname.replace('www.', '');
}

export function extractLinkedinName(string) {
  const parts = string.split('/');
  const companyIndex = parts.indexOf('company');
  if (parts.length > companyIndex && companyIndex !== -1) {
    return ` / ${parts[companyIndex + 1]}`;
  }
  return null;
}

export function limitLength(string, num) {
  if (!string || string.length < num) {
    return string;
  }
  return `${string.slice(0, num - 3)}...`;
}

export const parseNewLines = (value) => value.replace(/\\n/gu, '\n');

export const stringToCodeInt = (value) => {
  const val1 = value.toLowerCase().charCodeAt(0) - 96;
  const val2 = value.toLowerCase().charCodeAt(1) - 96;
  const val3 = value.toLowerCase().charCodeAt(2) - 96;
  return val1 + val2 + val3;
};

export function isValidUrl(urlString) {
  let url = null;
  try {
    url = new URL(urlString);
  } catch {
    return false;
  }
  return url?.protocol === 'http:' || url?.protocol === 'https:';
}

export function localeStringToNumber(localeString) {
  if (!localeString) {
    return null;
  }
  // Get the locale's grouping and decimal separators.
  // Need to use number that includes a decimal point and more than 3 digits
  const parts = new Intl.NumberFormat().formatToParts(12345.6);
  const groupSeparator = parts.find((part) => part.type === 'group').value;
  const decimalSeparator = parts.find((part) => part.type === 'decimal').value;

  // eslint-disable-next-line require-unicode-regexp
  const groupSeparatorRegex = new RegExp(`\\${groupSeparator}`, 'g');
  // Remove grouping separator (e.g., commas or periods in thousands)
  const cleanedString = localeString.replace(groupSeparatorRegex, '');
  // Replace the locale's decimal separator with a dot (.)
  const normalizedString = cleanedString.replace(decimalSeparator, '.');

  // Convert to number
  return parseFloat(normalizedString);
}
