import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { updateDiscoveryCustomColumnValueAction } from 'actions/explore/update-discovery-custom-column-value-action';
import CustomMarkdown from 'components/CustomMarkdown';
import CustomMarkdownCell from 'components/CustomMarkdownCell';
import PromptDisplayComponent from 'components/discovery/PromptDisplayComponent';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { formatAnswerBody } from 'utils/custom-analytics-utils';
import { parseNewLines } from 'utils/string-utils';
import { formatTimestamp } from 'utils/time-utils';

import CustomAnalyticsAnswerComponent from './CustomAnalyticsAnswerComponent';
import TableRowLoading from './TableRowLoading';

const payloadTextFromPayload = (payload) => {
  if (payload?.columnType?.includes('custom_analytics')) {
    return payload?.value?.answer || '';
  }
  if (payload?.value && typeof payload?.value === 'string') {
    return payload?.value;
  }
  return '';
};

export default function RenderCustomAnalyticsOutput({ value: payload }) {
  const { listId, companyName, columnName, extraParams } = payload || {};
  const dispatch = useDispatch();
  const [cellValue, setCellValue] = useState(payloadTextFromPayload(payload));
  const [openDialog, setOpenDialog] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [payloadText, setPayloadText] = useState('');

  useEffect(() => {
    const payloadTextTmp = payloadTextFromPayload(payload);
    if (payloadTextTmp === '') {
      setIsEdit(true);
    }
    setCellValue(payloadTextTmp);
    setPayloadText(payloadTextTmp);
  }, [payload]);

  if (payload?.value?.loading) {
    return <TableRowLoading />;
  }
  if (!payload) return null;

  if (!payloadText && payload?.columnType === 'basic_data_column') {
    return null;
  }
  const {
    sources,
    reasoning,
    tool_call_history: toolCallHistory,
    answer_type: answerType
  } = payload.value;

  const handleClose = () => {
    setOpenDialog(false);
    setIsEdit(false);
  };

  const handleCancel = () => {
    setCellValue(payloadText);
    if (payloadText) {
      setIsEdit(false);
    } else {
      setOpenDialog(false);
    }
  };

  const onCellValueChange = (event) => {
    setCellValue(event.target.value);
  };
  const onUpdateContent = () => {
    const newValue = payload?.columnType?.includes('custom_analytics')
      ? {
          ...(payload.value || {}),
          company_meta_id: payload?.companyMetaId,
          answer: cellValue
        }
      : cellValue;
    dispatch(
      updateDiscoveryCustomColumnValueAction(
        payload?.listId,
        payload?.companyListMetaId,
        payload?.columnId,
        newValue
      )
    );
    if (cellValue) {
      setIsEdit(false);
    }
  };

  return (
    <Stack justifyContent="center" width="100%" height="100%">
      <Box
        width="100%"
        onClick={() => {
          setOpenDialog(true);
          if (!cellValue) {
            setIsEdit(true);
          }
        }}
        sx={{ cursor: 'pointer', minHeight: '20px' }}>
        <Stack direction="row" alignItems="center" gap="4px">
          {parseNewLines(cellValue)
            .split('\n')
            .map((text, index) => (
              <CustomMarkdownCell key={index} body={text} />
            ))}
        </Stack>
      </Box>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ sx: { minWidth: '500px' } }}>
        <DialogTitle
          id="alert-dialog-title"
          sx={{ display: 'inline-flex', flexDirection: 'column' }}>
          <Stack direction="row" alignItems="center" gap="8px">
            {payload.companyLogo && (
              <Avatar src={payload.companyLogo} sx={{ width: 24, height: 24 }} variant="square" />
            )}
            <Typography variant="h4" color="greyColors.grey300">
              {`${payload?.companyName} / ${payload?.columnName}`}
            </Typography>
            {payload?.value?.timestamp && (
              <Typography variant="paragraph" color="greyColors.grey300">
                {formatTimestamp(payload.value.timestamp)}
              </Typography>
            )}
          </Stack>

          {payload?.columnInfo && (
            <PromptDisplayComponent
              listId={payload?.listId}
              prompt={payload?.columnInfo}
              mode="plain"
            />
          )}
        </DialogTitle>
        <DialogContent>
          <Stack gap="16px">
            {isEdit ? (
              <TextField
                multiline
                value={parseNewLines(cellValue)}
                onChange={onCellValueChange}
                fullWidth
                placeholder="Update the content here"
              />
            ) : (
              <CustomMarkdown
                body={formatAnswerBody(cellValue, answerType)}
                listId={listId}
                context={`${companyName} - ${columnName}`}
              />
            )}
            <CustomAnalyticsAnswerComponent
              value={{ value: { sources, reasoning, toolCallHistory } }}
              listId={listId}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          {!isEdit && <Button onClick={handleClose}>Close</Button>}
          {!isEdit && !extraParams?.disableEdit && (
            <Button onClick={() => setIsEdit(true)}>Edit</Button>
          )}
          {isEdit && <Button onClick={handleCancel}>Cancel</Button>}
          {isEdit && (
            <Button onClick={onUpdateContent} autoFocus>
              Update
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Stack>
  );
}

RenderCustomAnalyticsOutput.propTypes = {
  value: PropTypes.object
};
