import { Box, Stack } from '@mui/material';
import { loadAllFoldersAction } from 'actions/folders/load-all-folders-action';
import { loadAllSearchOpinions } from 'actions/opinions/load-all-search-opinions-action';
import { loadOverviewData } from 'actions/runs/load-overview-data-action';
import { loadRunDynamicCardsAction } from 'actions/runs/load-run-dynamic-cards-action';
import { loadRunSummary } from 'actions/runs/load-run-summary-action';
import { updateRunExtraDataAction } from 'actions/runs/update-run-extra-data-action';
import OverviewComponent from 'components/OverviewComponent';
import TabsComponent from 'components/TabsComponent';
import DeepDiveCustomCardsTabComponent from 'components/deep-dive/DeepDiveCustomCardsTabComponent';
import DeepDiveLeftPane from 'components/deep-dive/DeepDiveLeftPane';
import GroupInsightsCardsComponent from 'components/group-insights-cards/GroupInsightsCardsComponent';
import CompanySummaryComponent from 'components/summary/company/CompanySummaryComponent';
import MobileCompanySummaryComponent from 'components/summary/company/MobileCompanySummaryComponent';
import { COMPANY_INSIGHTS_PREFIX, ROUTES_PATHS } from 'constants/app-routes';
import { RESEARCH_TYPES } from 'constants/researches';
import { TILE_TYPE } from 'constants/tiles';
import { dispatch } from 'hooks/AppStateProvider';
import { useOnError } from 'hooks/OnErrorProvider';
import { useAppState } from 'hooks/state-context';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { updateCurrentFolderId } from 'reducer/folders-slice';
import { updateCurrentOpenResourceName } from 'reducer/resources-slice';
import { getDeepDiveDynamicCardsByDeepDiveId } from 'selectors/deep-dive';
import { getElementsByRecordId } from 'selectors/elements';
import { getFirstParentFolderIdOfResource } from 'selectors/folders';
import { getInsightsByRecordId } from 'selectors/insights';
import {
  getRunCompanyMetaById,
  getRunCompanyNameById,
  getRunSearchStatusById
} from 'selectors/runs';
import { getUserIsBasic, getUserIsDev } from 'selectors/user';
import { groupInsights } from 'utils/insights-utils';
import { isEmpty } from 'utils/objects-utils';
import { groupSort, groupToTitle } from 'utils/overviewGroup-utils';

export default function CompanyInsightsRunScreen() {
  const { runId, tabPath } = useParams();
  const { state } = useAppState();
  const reduxDispatch = useDispatch();
  const { forbiddenResource } = useOnError();

  const deepDiveId = parseInt(runId, 10);
  const isBasicUser = getUserIsBasic(state);

  const [insightsbyGroups, setInsightsbyGroups] = useState({});
  const [groupsSet, setGroupsSet] = useState(new Set());
  const [isCollapsed, setIsCollapsed] = useState(isBasicUser);

  const runName = getRunCompanyNameById(state, runId);
  const insights = getInsightsByRecordId(state, runId);
  const elements = getElementsByRecordId(state, runId);
  const folderId = useSelector((reduxState) =>
    getFirstParentFolderIdOfResource(reduxState, TILE_TYPE.RUN, deepDiveId)
  );
  const searchStatus = getRunSearchStatusById(state, runId);
  const companyMetaId = getRunCompanyMetaById(state, runId);
  const deepDiveDynamicCards = useSelector((reduxState) =>
    getDeepDiveDynamicCardsByDeepDiveId(reduxState, runId)
  );
  const dynamicCards = deepDiveDynamicCards ? Object.values(deepDiveDynamicCards) : [];
  const isDev = getUserIsDev(state);
  useEffect(() => {
    if (!insights) return;
    const localInsightsbyGroups = groupInsights(insights);
    setInsightsbyGroups(localInsightsbyGroups);
    setGroupsSet(
      Array.from(new Set(Object.keys({ ...localInsightsbyGroups, ...elements }))).sort(groupSort)
    );
  }, [insights, elements]);

  useEffect(() => {
    reduxDispatch(loadAllFoldersAction());
    dispatch(loadAllSearchOpinions(runId));
    reduxDispatch(loadRunDynamicCardsAction(runId));
    dispatch(updateRunExtraDataAction(runId));
  }, [runId, reduxDispatch]);

  useEffect(() => {
    if (searchStatus !== 'running' && isEmpty(insights)) {
      dispatch(loadRunSummary(runId, forbiddenResource));
      reduxDispatch(loadOverviewData(runId));
    }
  }, [runId, searchStatus, insights, forbiddenResource, reduxDispatch]);

  useEffect(() => {
    reduxDispatch(updateCurrentFolderId({ folderId }));
  }, [reduxDispatch, folderId]);

  useEffect(() => {
    if (runName) {
      reduxDispatch(
        updateCurrentOpenResourceName({
          resourceName: runName,
          resourceType: RESEARCH_TYPES.DEEP_DIVE.id
        })
      );
    }
  }, [reduxDispatch, runName]);

  if (tabPath === 'allCards') {
    return (
      <div id="all-cards-div">
        <OverviewComponent
          group="overview"
          title="Overview"
          recordId={runId}
          isMinified={false}
          insightsbyGroups={insightsbyGroups}
          elementsByGroups={elements}
          dynamicCards={dynamicCards}
        />
        {groupsSet && (
          <React.Fragment>
            {dynamicCards.length > 0 && (
              <DeepDiveCustomCardsTabComponent
                group="custom-cards"
                title="Custom Cards"
                deepDiveId={runId}
                cards={dynamicCards}
              />
            )}
            {[...groupsSet].map((group) => (
              <GroupInsightsCardsComponent
                key={group}
                group={group}
                title={groupToTitle(group)}
                recordId={runId}
                insights={insightsbyGroups[group]}
                element={elements[group]}
              />
            ))}
          </React.Fragment>
        )}
      </div>
    );
  }

  const heights = { navBarHeight: 50 };

  return (
    <Stack
      direction="row"
      sx={{
        overflow: 'hidden',
        height: `calc(100vh - ${heights.navBarHeight}px)`
      }}>
      <DeepDiveLeftPane
        deepDiveId={deepDiveId}
        companyMetaId={companyMetaId}
        heights={heights}
        isCollapsed={isCollapsed}
        setIsCollapsed={setIsCollapsed}
        disableCollapse={isBasicUser}
        isDev={isDev}
      />

      <Box
        id="deep-dive-screen"
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          minHeight: 'calc(100vh - 50px)',
          overflowY: 'auto'
        }}>
        <CompanySummaryComponent
          recordId={runId}
          backTag="Go to Home Page"
          addBottomPadding={true}
          insightsbyGroups={insightsbyGroups}
          elementsByGroups={elements}
          is_deep_dive_screen={true}
        />
        <MobileCompanySummaryComponent
          recordId={runId}
          backTag={'Go to Home Page'}
          backHref={ROUTES_PATHS.MAIN_PATH}
          addBottomPadding={true}
          insightsbyGroups={insightsbyGroups}
          elementsByGroups={elements}
        />

        <TabsComponent activeTabName={tabPath} tabPrefix={`${COMPANY_INSIGHTS_PREFIX}/${runId}`}>
          <OverviewComponent
            group="overview"
            title="Overview"
            recordId={runId}
            insightsbyGroups={insightsbyGroups}
            elementsByGroups={elements}
            isMinified={!isCollapsed}
            dynamicCards={dynamicCards}
          />
          {dynamicCards.length > 0 && (
            <DeepDiveCustomCardsTabComponent
              group="custom-cards"
              title="Custom Cards"
              deepDiveId={runId}
              cards={dynamicCards}
            />
          )}
          {groupsSet &&
            [...groupsSet].map((group) => (
              <GroupInsightsCardsComponent
                key={group}
                group={group}
                title={groupToTitle(group)}
                recordId={runId}
                insights={insightsbyGroups[group]}
                element={elements[group]}
              />
            ))}
        </TabsComponent>
      </Box>
    </Stack>
  );
}
