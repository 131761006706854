import { Input, Stack, Typography } from '@mui/material';
import { useAppUtils } from 'AppUtilsProvider';
import { editDiscoveryCustomColumnNameAction } from 'actions/explore/edit-discovery-custom-column-name-action';
import { TOAST_TYPES } from 'constants/toasts';
import EditIcon from 'icons/EditIcon';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { appTheme } from 'theme';

function RenderCustomAnalyticsHeader({ colDef }) {
  const dispatch = useDispatch();
  const { addToast } = useAppUtils();

  const [isHover, setIsHover] = useState(false);
  const [isNameEditable, setIsNameEditable] = useState(false);
  const [localColumnName, setLocalColumnName] = useState('');
  const {
    field: columnId,
    extraParams: { listId }
  } = colDef;

  useEffect(() => {
    if (isNameEditable) {
      const outsideClickHandle = () => {
        setIsNameEditable(false);
        setLocalColumnName('');
      };
      document.body.addEventListener('click', outsideClickHandle, true);
      return () => document.body.removeEventListener('click', outsideClickHandle, true);
    }
  }, [isNameEditable]);

  const updateLocalColumnName = (event) => {
    setLocalColumnName(event.target.value);
  };

  const onDownKey = (event) => {
    event.stopPropagation();
    if (!isNameEditable) return;
    if (event?.type === 'click' || event?.key === 'Enter') {
      setIsNameEditable(false);
      dispatch(editDiscoveryCustomColumnNameAction(listId, columnId, localColumnName));
      addToast(`Column name change to "${localColumnName}"`, TOAST_TYPES.SUCCESS);
      setLocalColumnName('');
    }
  };

  const makeColumnNameEditable = (event) => {
    event.stopPropagation();
    setIsNameEditable(true);
  };

  return (
    <Stack
      className="bulk-company-name"
      direction="row"
      gap="8px"
      alignItems="center"
      width="100%"
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}>
      {isNameEditable ? (
        <Input
          value={localColumnName}
          placeholder="New column name..."
          disableUnderline={true}
          autoFocus={true}
          onChange={updateLocalColumnName}
          onKeyPress={onDownKey}
          sx={{
            input: {
              color: 'greyColors.grey300',
              '&::placeholder': {
                ...appTheme.typography.paragraph
              }
            },
            backgroundColor: 'transparent',
            ...appTheme.typography.paragraphBold
          }}
        />
      ) : (
        <Stack direction="row" alignItems="center" gap="8px" onClick={makeColumnNameEditable}>
          <Typography variant="paragraphSemiBold" color="greyColors.grey300" noWrap display="block">
            {colDef.headerName}
          </Typography>
          {isHover && <EditIcon />}
        </Stack>
      )}
    </Stack>
  );
}

RenderCustomAnalyticsHeader.propTypes = {
  colDef: PropTypes.object,
  showCheckBox: PropTypes.bool,
  isChecked: PropTypes.bool,
  onCheck: PropTypes.func,
  allowCheckbox: PropTypes.bool
};
export default React.memo(RenderCustomAnalyticsHeader);
