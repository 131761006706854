import { Stack, Typography } from '@mui/material';
import { wrapWithError } from 'components/ErrorBoundaryComponent';
import IconButtonComponent from 'components/IconButtonComponent';
import AddDeepDiveDynamicCardDialog from 'components/deep-dive/AddDeepDiveDynamicCardDialog';
import EntrichTableDialog from 'components/discovery/EntrichTableDialog';
import PromptInputComponent from 'components/discovery/PromptInputComponent';
import { CUSTOM_ANALYTICS_PLACEMENT, PROMPT_PREFIX } from 'constants/custom-analytics';
import { useOldStateSelector } from 'hooks/useOldStateSelector';
import AssistantWandAvatarIcon from 'icons/AssistantWandAvatarIcon';
import CloseIcon from 'icons/CloseIcon';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getThreadKey } from 'reducer/custom-analytics';
import { getCompanyMetasMergedRowsMapByListId } from 'selectors/companyMetas';
import { getCustomAnalyticsThreadPostsById } from 'selectors/custom-analytics';
import { getUserFeatureFlags } from 'selectors/user';
import { INITIAL_PROMPT_CONFIG } from 'utils/prompts-utils';

import ChooseEntrichDomainKnowledgeNameDialog from './ChooseEntrichDomainKnowledgeNameDialog';
import CustomAnalyticsThread from './CustomAnalyticsThread';

function CustomAnalyticsWidget({ listId, deepDiveId, companyMetaId, isDev, placement, onClose }) {
  const postsContainerRef = useRef();
  const threadKey = getThreadKey(listId, companyMetaId);
  const featureFlags = useOldStateSelector(getUserFeatureFlags);
  const canAutomaticallyAddColumn = featureFlags?.includes('ca_automatically_create_column');
  const thread = useSelector((reduxState) =>
    getCustomAnalyticsThreadPostsById(reduxState, threadKey)
  );
  const companies = useSelector((reduxState) =>
    getCompanyMetasMergedRowsMapByListId(reduxState, listId)
  );

  const [promptConfig, setPromptConfig] = useState({
    ...INITIAL_PROMPT_CONFIG,
    searchTheWeb: true
  });

  const [enrichPostId, setEnrichPostId] = useState(null);
  const [domainKnowledgePostId, setDomainKnowledgePostId] = useState(null);
  const [scrollToEnd, setScrollToEnd] = useState(false);

  useEffect(() => {
    setPromptConfig({
      ...INITIAL_PROMPT_CONFIG,
      forEachCompany: listId ? INITIAL_PROMPT_CONFIG.forEachCompany : true,
      searchTheWeb: true
    });
  }, [listId, deepDiveId, placement]);

  useEffect(() => {
    if (scrollToEnd) {
      postsContainerRef.current.scrollTop = postsContainerRef.current.scrollHeight;
    }
    setScrollToEnd(false);
  }, [scrollToEnd]);

  const shouldShowAddToDomainKnowledge = (postId) =>
    (placement === CUSTOM_ANALYTICS_PLACEMENT.EXPLORE && thread[postId].status) === '100%' &&
    !thread[postId].query.includes(`{{${PROMPT_PREFIX.FOR_EACH_COMPANY.prefix}}}`);

  const getEnrichButtonText = () => {
    if (placement === CUSTOM_ANALYTICS_PLACEMENT.EXPLORE) {
      return canAutomaticallyAddColumn ? null : 'Add Column';
    }
    return 'Create Card';
  };

  return (
    <React.Fragment>
      {onClose && (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            height: '48px',
            width: '100%',
            zIndex: '1',
            padding: '0px 24px 0px 24px',
            color: 'secondary.secondary150',
            backgroundColor: 'greyColors.grey50',
            borderBottom: '1px solid',
            borderColor: 'background.bg150',
            boxShadow: '0px 3px 6px 0px rgba(84, 106, 140, 0.08)',
            boxSizing: 'border-box'
          }}>
          <Stack direction="row" alignItems="center" gap="12px">
            <AssistantWandAvatarIcon />
            <Typography variant="paragraphBold" color="greyColors.grey300">
              Custom Analytics Assistant
            </Typography>
          </Stack>
          <IconButtonComponent onClick={onClose}>
            <CloseIcon onClick={onClose} />
          </IconButtonComponent>
        </Stack>
      )}
      <React.Fragment>
        <Stack
          ref={postsContainerRef}
          direction="column"
          gap="48px"
          padding="0 16px 0px 16px"
          sx={{
            boxSizing: 'border-box',
            overflowY: 'auto',
            overflowX: 'hidden',
            marginTop: 'auto'
          }}>
          <Stack direction="row" alignItems="center" gap="16px">
            <Stack
              sx={{
                minWidth: 32,
                height: 32,
                backgroundColor: 'primary.primary50',
                color: 'white',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 16
              }}>
              <AssistantWandAvatarIcon />
            </Stack>
            <Typography variant="paragraph" color="greyColors.grey300">
              Welcome! How can I help you?
            </Typography>
          </Stack>
          {thread &&
            Object.keys(thread).map((postId) => (
              <CustomAnalyticsThread
                key={postId}
                post={thread[postId]}
                listId={listId}
                companyMetaId={companyMetaId}
                companies={companies}
                enrichButtonText={getEnrichButtonText()}
                setEnrichPostId={setEnrichPostId}
                setDomainKnowledgePostId={setDomainKnowledgePostId}
                shouldShowAddToDomainKnowledge={shouldShowAddToDomainKnowledge(postId)}
                showToolCallHistory={isDev}
                deepDiveId={deepDiveId}
                setPromptConfig={setPromptConfig}
                placement={placement}
              />
            ))}
        </Stack>
        <PromptInputComponent
          listId={listId}
          companyMetaId={companyMetaId}
          placement={placement}
          onAddNewPost={() => setScrollToEnd(true)}
          deepDiveId={deepDiveId}
          promptConfig={promptConfig}
          setPromptConfig={setPromptConfig}
        />
        {listId && (
          <EntrichTableDialog
            open={Boolean(enrichPostId)}
            onClose={() => setEnrichPostId(null)}
            listId={listId}
            enrichedPostId={enrichPostId}
          />
        )}
        {placement === CUSTOM_ANALYTICS_PLACEMENT.DEEP_DIVE && (
          <AddDeepDiveDynamicCardDialog
            open={Boolean(enrichPostId)}
            onClose={() => setEnrichPostId(null)}
            deepDiveId={deepDiveId}
            companyMetaId={companyMetaId}
            enrichedPostId={enrichPostId}
          />
        )}
        {listId && (
          <ChooseEntrichDomainKnowledgeNameDialog
            open={Boolean(domainKnowledgePostId)}
            onClose={() => setDomainKnowledgePostId(null)}
            listId={listId}
            domainKnowledgePostId={domainKnowledgePostId}
          />
        )}
      </React.Fragment>
    </React.Fragment>
  );
}

CustomAnalyticsWidget.propTypes = {
  listId: PropTypes.number,
  deepDiveId: PropTypes.number,
  companyMetaId: PropTypes.number,
  isDev: PropTypes.bool,
  placement: PropTypes.string,
  onClose: PropTypes.func
};

export default wrapWithError(CustomAnalyticsWidget);
