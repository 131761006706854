import { CHAT_GPT_MODEL_VERSION } from 'constants/custom-analytics';
import { getPusherClient } from 'hooks/PusherProvider';
import {
  addPostAnswerToThread,
  addPostQueryToThread,
  addPostRecordIdToThread,
  addPostStatusToThread,
  getThreadKey,
  updatePostMessagesInThread,
  updateThreadProccessingMode
} from 'reducer/custom-analytics';
import {
  getCustomAnalyticsThreadIdById,
  getCustomAnalyticsThreadSpecificPostById
} from 'selectors/custom-analytics';
import { getCompanyMetasByListId } from 'selectors/explore';
import { getRunProgressChannelName } from 'utils/pusher-utils';

function fetchCustomAnalyticsRecordData(threadKey, postId, customAnalyticsecordId) {
  return (dispatch, _getState, apiClient) => {
    apiClient.customAnalytics
      .getCustomAnalyticsRecordData(customAnalyticsecordId)
      .then((res) => {
        if (res.ok) {
          const {
            thread_id: threadId,
            message,
            messages,
            response_type: responseType,
            plan,
            image
          } = res.data;
          dispatch(
            addPostAnswerToThread({
              threadKey,
              postId,
              message,
              threadId,
              messages,
              responseType,
              image,
              plan
            })
          );
        } else {
          dispatch(
            addPostAnswerToThread({
              threadKey,
              postId,
              message: { answer: 'Something went wrong, please try again' }
            })
          );
        }
        dispatch(
          addPostStatusToThread({
            threadKey,
            postId,
            status: '100%'
          })
        );
      })
      .catch(() => {
        dispatch(
          addPostStatusToThread({
            threadKey,
            postId,
            status: '100%'
          })
        );
        dispatch(
          addPostAnswerToThread({
            threadKey,
            postId,
            message: { answer: 'Something went wrong, please try again' }
          })
        );
      });
  };
}

function fetchCustomAnalyticsRecordDataAndUpdateMessages(
  threadKey,
  postId,
  customAnalyticsRecordId
) {
  return (dispatch, getState, apiClient) => {
    apiClient.customAnalytics.getCustomAnalyticsRecordData(customAnalyticsRecordId).then((res) => {
      if (res.ok) {
        const { messages } = res.data;
        dispatch(updatePostMessagesInThread({ threadKey, postId, messages }));
      }
    });
  };
}

export function pusherBindToCARecordId({
  listId,
  recordId,
  companyMetaId,
  threadKey,
  postId,
  dispatch,
  state
}) {
  const pusher = getPusherClient();
  const channelName = getRunProgressChannelName(recordId);
  const channel = pusher.subscribe(channelName);
  channel.bind('partial_result', (data) => {
    const post = getCustomAnalyticsThreadSpecificPostById(state, threadKey, postId);
    if (post?.messages) {
      dispatch(fetchCustomAnalyticsRecordDataAndUpdateMessages(threadKey, postId, recordId));
    } else {
      const companies = listId
        ? getCompanyMetasByListId(state, listId)
        : [{ company_meta_id: companyMetaId }];
      const messages = companies.map((company) => ({
        company_meta_id: company.company_meta_id,
        loading: true
      }));
      dispatch(updatePostMessagesInThread({ threadKey, postId, messages }));
      dispatch(fetchCustomAnalyticsRecordDataAndUpdateMessages(threadKey, postId, recordId));
    }
    dispatch(
      addPostStatusToThread({
        threadKey,
        postId,
        status: '',
        statusMessage: `${data.n_completed_companies} companies results`
      })
    );
  });
  channel.bind('progress', (data) => {
    if (data.progress === 1) {
      pusher.unsubscribe(channelName);
      dispatch(fetchCustomAnalyticsRecordData(threadKey, postId, recordId));
    } else {
      dispatch(
        addPostStatusToThread({
          threadKey,
          postId,
          status: `${Math.round(data.progress * 100)}%`,
          statusMessage: data.message
        })
      );
    }
  });
}

export function createNewPostInCustomAnalyticsThreadAsyncAction({
  listId,
  companyMetaId,
  query,
  useColumn,
  answerType,
  useGpt4,
  usePlanner,
  selectedCompanies,
  temperature,
  folderId,
  supportingResourceId,
  columns,
  forEachCompany
}) {
  return (dispatch, getState, apiClient) => {
    const threadKey = getThreadKey(listId, companyMetaId);
    const currentThreadId = getCustomAnalyticsThreadIdById(getState(), threadKey);
    const postId = new Date().getTime();
    const body = { question: query, company_list_id: listId };
    if (folderId) {
      body.folder_id = folderId;
    }
    if (currentThreadId) {
      body.threadId = currentThreadId;
    }
    if (useColumn?.id) {
      body.column_id = useColumn?.id;
    }
    if (useGpt4) {
      body.model = CHAT_GPT_MODEL_VERSION;
    }
    if (usePlanner) {
      body.action = 'generate_plan_and_execute';
    }
    if (temperature) {
      body.temperature = temperature / 10;
    }
    if (answerType) {
      body.answer_type = answerType;
    }
    if (selectedCompanies) {
      body.company_meta_ids = selectedCompanies;
    }
    if (supportingResourceId) {
      body.supporting_resource_id = supportingResourceId;
    }
    if (columns) {
      body.columns = columns;
    }
    if (forEachCompany) {
      body.for_each_company = forEachCompany;
    }
    dispatch(addPostQueryToThread({ threadKey, postId, query, body }));
    // dispatch(updateThreadProccessingMode({ threadKey, isProccessing: true }));
    apiClient.customAnalytics
      .queryCustomAnalyticsAssistantAsync(body)
      .then((res) => {
        if (res.ok) {
          const customAnalyticsRecordId = res.data.record_id;
          const currentState = getState();
          dispatch(addPostRecordIdToThread({ threadKey, postId, customAnalyticsRecordId }));
          pusherBindToCARecordId({
            listId,
            recordId: customAnalyticsRecordId,
            threadKey,
            postId,
            companyMetaId,
            dispatch,
            state: currentState
          });
        } else {
          dispatch(
            addPostAnswerToThread({
              threadKey,
              postId,
              message: { answer: 'Something went wrong, please try again' }
            })
          );
        }
        dispatch(updateThreadProccessingMode({ threadKey, isProccessing: false }));
      })
      .catch(() => {
        dispatch(
          addPostAnswerToThread({
            threadKey,
            postId,
            message: { answer: 'Something went wrong, please try again' }
          })
        );
        dispatch(updateThreadProccessingMode({ threadKey, isProccessing: false }));
      });
  };
}
