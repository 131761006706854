export function getColumnValue(data, recordId) {
  return {
    answer: data.message.answer,
    answer_type: data.message.answer_type,
    reasoning: data.message.reasoning,
    tool_call_history: data.message.tool_call_history,
    sources: data.message.sources ? data.message.sources : null,
    custom_analytics_record_id: recordId,
    company_meta_id: data.company_meta_id
  };
}
