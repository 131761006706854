import {
  Chip,
  Divider,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
  Tab,
  Tabs,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material';
import SelectFiltersComponent from 'components/tile/discovery/SelectFiltersComponent';
import { CUSTOM_ANALYTICS_PLACEMENT, PROMPT_PREFIX } from 'constants/custom-analytics';
import { DISCOVERY_TYPES, DISCOVERY_TYPES_TITLES } from 'constants/discovery';
import PropTypes from 'prop-types';
import React from 'react';
import { appTheme } from 'theme';

/* eslint-disable max-lines-per-function */
function CustomAnalyticsTemplatesChips({
  promptConfig,
  setPromptConfig,
  columnIds,
  resourcesTypes,
  domainKnowledge,
  filters,
  setFilters,
  handleDiscoverMethodChange,
  placement,
  featureFlags
}) {
  const { forEachCompany, useColumn, useColumns, useDomainKnowledge } = promptConfig;
  const toggleField = (field) => {
    setPromptConfig((prev) => {
      const copy = { ...prev };
      copy[field] = !copy[field];
      if (field === 'useColumn') {
        if (prev.useColumn) {
          copy.column = null;
        } else if (columnIds && columnIds.length > 0) {
          [copy.column] = columnIds;
        }
      }
      if (field === 'useColumns') {
        if (prev.useColumns) {
          copy.columns = null;
        } else if (columnIds && columnIds.length > 0) {
          copy.columns = [columnIds[0]];
        }
      }
      if (field === 'forEachCompany') {
        if (prev.useColumn) {
          copy.companies = null;
        }
      }
      if (field === 'useDomainKnowledge') {
        if (prev.useDomainKnowledge) {
          copy.resource = null;
          copy.useSearch = true;
        } else if (resourcesTypes && resourcesTypes.length > 0) {
          [copy.resource] = resourcesTypes;
        }
      }
      if (field === 'useDiscover') {
        if (prev.useDiscover) {
          copy.discoverMethod = DISCOVERY_TYPES.TEXT;
          copy.searchTheWeb = true;
        } else {
          copy.useColumn = false;
          copy.useColumns = false;
          copy.forEachCompany = false;
          copy.useDomainKnowledge = false;
          copy.searchTheWeb = false;
        }
      }
      return copy;
    });
  };

  const handleActiveTab = () => {
    toggleField('useDiscover');
  };

  const isFilterMissing = Boolean(Object.values(filters).find((filter) => !filter.length));
  const tabSx = {
    gap: '8px',
    '&:hover': {
      color: appTheme.palette.primary.primary25
    },

    '&.Mui-selected': {
      color: appTheme.palette.primary.primary50
    },
    minHeight: '10px'
  };
  return (
    <Stack direction="column" gap="16px" paddingTop="8px">
      {placement === CUSTOM_ANALYTICS_PLACEMENT.EXPLORE && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          gap="8px"
          sx={{ borderBottom: '1px solid', borderColor: 'greyColors.grey50' }}>
          {' '}
          <Tabs
            value={promptConfig.useDiscover ? 1 : 0}
            textColor="secondary"
            onChange={handleActiveTab}>
            <Tab
              label={<Typography variant="paragraphSemiBold">Research</Typography>}
              color="primary"
              sx={tabSx}
            />
            <Tab
              label={<Typography variant="paragraphSemiBold">Discover</Typography>}
              color="primary"
              sx={tabSx}
            />
          </Tabs>
          {!promptConfig.useDiscover && (
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    checked={promptConfig.searchTheWeb}
                    onChange={() => toggleField('searchTheWeb')}
                  />
                }
                label={
                  <Typography variant="paragraphMedium" color={'background.bg250'}>
                    {promptConfig.searchTheWeb ? 'Search The Web' : 'Table Only'}
                  </Typography>
                }
                labelPlacement="start"
              />
            </FormGroup>
          )}
        </Stack>
      )}

      {promptConfig.useDiscover ? (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          gap="8px"
          flexWrap="wrap">
          <ToggleButtonGroup
            value={promptConfig.discoverMethod}
            exclusive
            onChange={(_event, newValue) => handleDiscoverMethodChange(newValue)}
            size="small">
            <ToggleButton value={DISCOVERY_TYPES.TEXT} sx={{ height: '32px' }}>
              <Typography variant="paragraphMedium">
                {DISCOVERY_TYPES_TITLES[DISCOVERY_TYPES.TEXT]}
              </Typography>
            </ToggleButton>
            <ToggleButton value={DISCOVERY_TYPES.PEERS} sx={{ height: '32px' }}>
              {DISCOVERY_TYPES_TITLES[DISCOVERY_TYPES.PEERS]}
            </ToggleButton>
            <ToggleButton value={DISCOVERY_TYPES.PARTNERS} sx={{ height: '32px' }}>
              {DISCOVERY_TYPES_TITLES[DISCOVERY_TYPES.PARTNERS]}
            </ToggleButton>
            <ToggleButton value={DISCOVERY_TYPES.URL} sx={{ height: '32px' }}>
              {DISCOVERY_TYPES_TITLES[DISCOVERY_TYPES.URL]}
            </ToggleButton>
          </ToggleButtonGroup>
          <SelectFiltersComponent
            filters={filters}
            setFilters={setFilters}
            isFilterMissing={isFilterMissing}
          />
        </Stack>
      ) : (
        <Stack
          direction="row"
          flexWrap="wrap"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          gap="8px">
          <Stack direction="row" flexWrap="wrap" alignItems="center" gap="8px">
            <React.Fragment>
              {placement === CUSTOM_ANALYTICS_PLACEMENT.EXPLORE && (
                <Chip
                  label={
                    featureFlags.includes('ca_automatically_create_column')
                      ? PROMPT_PREFIX.ADD_COLUMN.title
                      : PROMPT_PREFIX.FOR_EACH_COMPANY.title
                  }
                  onClick={() => toggleField('forEachCompany')}
                  disabled={promptConfig.useDiscover}
                  sx={{
                    width: '140px',
                    cursor: 'pointer',
                    border: '1px solid',
                    borderColor: forEachCompany ? 'background.bg100' : 'greyColors.grey100',
                    backgroundColor: forEachCompany ? 'background.bg100' : 'transparent',
                    boxShadow: forEachCompany ? '0px 1px 5px 0px #1018281A' : 'none'
                  }}
                />
              )}
              {placement === CUSTOM_ANALYTICS_PLACEMENT.DEEP_DIVE && (
                <Chip
                  label={
                    placement === CUSTOM_ANALYTICS_PLACEMENT.EXPLORE
                      ? PROMPT_PREFIX.USING_COLUMN.title
                      : PROMPT_PREFIX.USING_CARD.title
                  }
                  onClick={() => toggleField('useColumn')}
                  disabled={promptConfig.useDomainKnowledge || promptConfig.useDiscover}
                  sx={{
                    width: '140px',
                    cursor: 'pointer',
                    border: '1px solid',
                    borderColor: useColumn ? 'background.bg100' : 'greyColors.grey100',
                    backgroundColor: useColumn ? 'background.bg100' : 'transparent',
                    boxShadow: useColumn ? '0px 1px 5px 0px #1018281A' : 'none'
                  }}
                />
              )}
              {placement === CUSTOM_ANALYTICS_PLACEMENT.EXPLORE && (
                <Chip
                  label={PROMPT_PREFIX.USING_COLUMNS.title}
                  onClick={() => toggleField('useColumns')}
                  disabled={promptConfig.useDomainKnowledge || promptConfig.useDiscover}
                  sx={{
                    width: '140px',
                    cursor: 'pointer',
                    border: '1px solid',
                    borderColor: useColumns ? 'background.bg100' : 'greyColors.grey100',
                    backgroundColor: useColumns ? 'background.bg100' : 'transparent',
                    boxShadow: useColumns ? '0px 1px 5px 0px #1018281A' : 'none'
                  }}
                />
              )}
              <Divider orientation="vertical" flexItem />
              {placement === CUSTOM_ANALYTICS_PLACEMENT.EXPLORE && (
                <Chip
                  label={PROMPT_PREFIX.USING_DOMAIN_KNOWLEDGE.title}
                  onClick={() => toggleField('useDomainKnowledge')}
                  disabled={
                    promptConfig.useColumn || !domainKnowledge || domainKnowledge?.length <= 0
                  }
                  sx={{
                    width: '140px',
                    cursor: 'pointer',
                    border: '1px solid',
                    borderColor: useDomainKnowledge ? 'background.bg100' : 'greyColors.grey100',
                    backgroundColor: useDomainKnowledge ? 'background.bg100' : 'transparent',
                    boxShadow: useDomainKnowledge ? '0px 1px 5px 0px #1018281A' : 'none'
                  }}
                />
              )}
              {placement === CUSTOM_ANALYTICS_PLACEMENT.DEEP_DIVE && (
                <FormGroup sx={{ marginLeft: 'auto', justifySelf: 'flex-end' }}>
                  <FormControlLabel
                    control={
                      <Switch
                        size="small"
                        checked={promptConfig.searchTheWeb}
                        onChange={() => toggleField('searchTheWeb')}
                      />
                    }
                    label={
                      <Typography variant="paragraphMedium" color={'background.bg250'}>
                        {promptConfig.searchTheWeb ? 'Search The Web' : 'Company Data Only'}
                      </Typography>
                    }
                    labelPlacement="start"
                  />
                </FormGroup>
              )}
            </React.Fragment>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}

CustomAnalyticsTemplatesChips.propTypes = {
  promptConfig: PropTypes.object,
  setPromptConfig: PropTypes.func,
  columnIds: PropTypes.array,
  resourcesTypes: PropTypes.array,
  domainKnowledge: PropTypes.object,
  filters: PropTypes.object,
  setFilters: PropTypes.func,
  handleDiscoverMethodChange: PropTypes.func,
  placement: PropTypes.string,
  featureFlags: PropTypes.array
};

export default CustomAnalyticsTemplatesChips;
